<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div :style="bannerStyle">
          <h1 class="text-center text-white mt-2 mb-1">{{ $t('welcome.title', { name: loggedUser.name }) }}</h1>
          <div class="mx-5 px-25"><h3 class="text-center text-white mx-xl-5 px-lg-5 font-weight-normal">{{ welcomeTitle }}</h3></div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6">
        <b-card
          class="mt-2"
        >
          <b-card-title>
            <h3 class="font-weight-bolder">{{ stepsTitle }}</h3>
          </b-card-title>
          <!-- Steps -->
          <div v-for="(item, index) in items" :key="index">
            <b-card class="mb-75" :class=" item.completed ? 'steps-card steps-card--confirmed': 'steps-card'">
              <div class=" d-flex align-items-center">
                <div v-html="`<span class='font-weight-bolder'>${item.title}</span> ${item.rest}`" />
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
      <!-- Video -->
      <b-col cols="12" lg="6" class="px-3">
        <h3 class="mt-lg-3 pt-25 font-weight-bolder">{{ videoTitle }}</h3>
        <p class="mt-1 mb-2 font-weight-bold">{{ videoSubtitle }}</p>
        <b-embed v-if="linkVideo" :src="linkVideo" style="border-radius: 1.2rem"/>
        <img v-else-if="defaultImage" :src="defaultImage" style="max-width: 95%; border-radius: 1.2rem"/>
        <video-player
          v-else
          :src=" currentLocale === 'en' ? englishVideo : spanishVideo"
          style="border-radius:1.2rem; overflow:hidden"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Banner from '@/assets/images/brand/Banner_dashboard.svg';
import VideoPlayer from '@core/components/video-player/VideoPlayer.vue';

export default {
  components: {
    VideoPlayer,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    englishVideo: {
      type: String,
      required: true,
    },
    spanishVideo: {
      type: String,
      required: true,
    },
    defaultImage: {
      type: String,
      default: null,
    },
    url: {
      type: Function,
      required: true,
    },
    stepsTitle: {
      type: String,
      required: true,
    },
    videoTitle: {
      type: String,
      required: true,
    },
    videoSubtitle: {
      type: String,
      required: true,
    },
    linkVideo: {
      type: String,
      default: null,
    },
    welcomeTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    banner() {
      return Banner;
    },
    bannerStyle() {
      return {
        'background-image': `url(${this.banner})`,
        'background-repeat': 'no-repeat',
        'background-size': 'cover',
        ...(this.isSmall ? { 'background-position': 'center' } : {'background-position': 'center'}),
        'border-radius': '1.2rem',
        'display': 'flex',
        'flex-direction': 'column',
        'justify-content': "center",
        'padding-top': '60px',
        'padding-bottom': '65px',
      };
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

.steps-card {
  border-radius: .75rem !important;
  &--confirmed {
    border: 0;
    background-color: lighten(theme-color('primary'), 45%),
  }
}
</style>
